<template>
    <div class="user-base-info">
        <div class="headline">个人信息</div>
        <div class="avatarBox f-c-b">
            <el-avatar shape="circle" :size="100" fit="cover" :src="userInfo.userAvatarUrl"></el-avatar>
            <UploadAvatar :avatar="userInfo.userAvatarUrl" @refresh="refresh"></UploadAvatar>
        </div>
        <div class="infoBox">
            <div class="info f-c-b">
                <div class="f-c leftBox">
                    <div class="titleBox">
                        <span class="required">*</span>
                        <span class="title">昵称</span>
                    </div>
                    <span class="value">{{ userInfo.userName }}</span>
                </div>

                <span class="change" @click="change('name')">修改</span>
            </div>
            <div class="info f-c-b">
                <div class="f-c leftBox">
                    <div class="titleBox">
                        <span class="required">*</span>
                        <span class="title">手机号</span>
                    </div>
                    <span class="value">{{ userInfo.phone }}</span>
                </div>

                <span class="change" @click="change('phone')">修改</span>
            </div>
            <div class="info f-c-b">
                <div class="leftBox">
                    <div class="titleBox">
                        <span class="required">*</span>
                        <span class="title">登录密码</span>
                    </div>
                </div>

                <span class="change" @click="change('pwd')">修改</span>
            </div>
            <div class="info f-c-b">
                <div class="f-c leftBox">
                    <div class="titleBox">
                        <span class="required">*</span>
                        <span class="title">当前角色</span>
                    </div>
                    <span class="value">{{ userInfo.spType == "P" ? '鞋包成品商' : '鞋包材料供应商' }}</span>
                </div>

                <span class="changebtn" @click="change('role')">点击转换角色</span>
            </div>
        </div>
        <Dialog :title="title" width="690px" :visible="dialogShow" @closeDialog="closeDialog" class="commonDialog">
            <div class="change-name" v-if="DialogType == 'name'">
                <div class="name">当前昵称：{{ userInfo.userName }}</div>
                <el-input v-model.trim="nameValue" clearable placeholder="请输入昵称">
                </el-input>
                <!-- <ChineseCharactersInput :modelValue="nameValue" @getValue="getValue"></ChineseCharactersInput> -->
                <el-button type="primary" @click="updateUserInfo">确定</el-button>
            </div>
            <div class="change-phone" v-else-if="DialogType == 'phone'">
                <RegisterForm type="change"></RegisterForm>
            </div>
            <div class="change-pwd" v-else-if="DialogType == 'pwd'">
                <ForgotPwdForm :sendPhone="userInfo.phone" type="user"></ForgotPwdForm>
            </div>
            <div class="change-role" v-else-if="DialogType == 'role'">
                <div v-if="userInfo.spType == 'P'" class="f-cl-c">
                    <img src="@/assets/supplier2.png" alt="">
                    <div class="name">鞋包成品商</div>
                    <!-- <div class="tip">采购和查找原材料</div> -->
                    <div class="btn" @click="changeUserType('S')">转为鞋包材料供应商</div>
                </div>
                <div v-else-if="userInfo.spType == 'S'" class="f-cl-c">
                    <img src="@/assets/purchaser2.png" alt="">
                    <div class="name">鞋包材料供应商</div>
                    <!-- <div class="tip">入驻识料</div> -->
                    <div class="btn" @click="changeUserType('P')">转为鞋包成品商</div>
                </div>
            </div>
        </Dialog>
        <!-- 职业 -->
        <Dialog title="请选择您的具体职业" width="690px" :visible="jodVisibleVisible" class="jodDialog">
            <div class="tip">便于为您提供最佳服务</div>
            <div v-if="$getStorage('spType') == 'S'" class="f-w">
                <div :class="Jod == item.id ? 'select' : 'btns'" v-for="(item, index) in supplyJodList" :key="index"
                    @click="Jod = item.id">{{ item.name }}</div>
            </div>
            <div v-else class="f-w">
                <div :class="Jod == item.id ? 'select' : 'btns'" v-for="(item, index) in purchaseList" :key="index"
                    @click="Jod = item.id">{{ item.name }}</div>
            </div>
            <div v-if="$getStorage('InvestigateFlag') == 'I'" class="f-c invitationCode">
                <div class="title">邀请码</div>
                <el-input v-model="invitationCode" placeholder="请输入邀请码"></el-input>
            </div>
            <div class="verifyBtn" @click="verifyJod()">确定</div>
        </Dialog>
        <!-- <Dialog title="注销用户" width="690px" :visible="outVisibleVisible" class="outDialog"
            @closeDialog="outVisibleVisible = false">
            <div class="tip">确定注销？</div>
            <div class="f-c-c">
                <div class="verifyBtn" @click="verifyDeleteUser()">确定</div>
                <div class="cancelBtn" @click="outVisibleVisible = false">取消</div>
            </div>

        </Dialog> -->
    </div>
</template>
<script>
import { mapState } from 'vuex';
// import { beforeUploadMixin } from '@/mixins/BeforeUploadImageMixin';
export default {
    inject: ["reload"],
    components: { UploadAvatar: () => import(/* webpackChunkName: "UploadAvatar" */ '@/pages/user/components/UploadAvatar.vue') },
    data() {
        return {
            userInfo: {},
            title: '',
            dialogShow: false,
            DialogType: '',//name-修改名称 pwd-修改密码 phone-修改手机号
            nameValue: '',
            isTrigger: true,//是否触发请求
            jodVisibleVisible: false,
            outVisibleVisible: false,
            Jod: '',
            supplyJodList: [  // 供应类职业
                {
                    id: 10001,
                    value: '材料工厂',
                    name: '材料工厂'
                }, {
                    id: 10002,
                    value: '贸易档口',
                    name: '贸易档口'
                }, {
                    id: 10003,
                    value: '成品鞋、包厂',
                    name: '成品鞋、包厂'
                }, {
                    id: 10004,
                    value: '皮、化工原料供应商',
                    name: '皮、化工原料供应商'
                }, {
                    id: 10005,
                    value: '机械配件供应商',
                    name: '机械配件供应商'
                }, {
                    id: 10099,
                    value: '其它',
                    name: '其它 '
                }
            ],
            purchaseList: [  // 采购职业
                {
                    id: 1,
                    value: '设计师',
                    name: '设计师'
                }, {
                    id: 2,
                    value: '采购员',
                    name: '采购员'
                }, {
                    id: 3,
                    value: '开发员',
                    name: '开发员'
                }, {
                    id: 4,
                    value: '板师',
                    name: '板师'
                }, {
                    id: 5,
                    value: '人力资源招聘',
                    name: '人力资源招聘'
                }, {
                    id: 6,
                    value: '企业主',
                    name: '企业主'
                }, {
                    id: 7,
                    value: '商协会',
                    name: '商协会'
                }, {
                    id: 8,
                    value: '杂志媒体',
                    name: '杂志媒体'
                }, {
                    id: 9,
                    value: '贸易公司',
                    name: '贸易公司'
                }, {
                    id: 999,
                    value: '其它',
                    name: '其它'
                }
            ],
            invitationCode: '',  // 邀请码
        };
    },
    created() {
        this.getUserInfo();
    },
    computed: {
        ...mapState({ userName: 'userName', userAvatarUrl: 'userAvatarUrl', userId: 'userId' })
    },
    // mixins: [beforeUploadMixin],
    methods: {
        getValue(data) {
            this.nameValue = data.value
        },
        // 刷新当前页面
        refresh() {
            this.reload();
            this.getUserInfo();
        },
        // 获取个人信息
        getUserInfo() {
            let that = this,
                data = {
                    userId: parseInt(that.userId),
                    token: this.$getStorage("token")
                };
            that.$http.getUserInfo(data).then(res => {
                let { userAvatarUrl, userName } = res.data;
                that.userInfo = res.data;
                that.$store.commit('saveUserAvatarUrl', userAvatarUrl)
                that.$store.commit('saveUserName', userName);
                if ((res.data.spType == 'S' && res.data.supplyInvestigateFlag != 'N')) {
                    that.jodVisibleVisible = true;
                } else if ((res.data.spType == 'P' && res.data.procureInvestigateFlag != 'N')) {
                    that.jodVisibleVisible = true;
                }
            });
        },
        // 修改昵称信息
        updateUserInfo() {
            let that = this,
                data = {
                    userId: parseInt(that.userId),
                    userName: that.nameValue.trim(),
                    token: that.$getStorage("token")
                };
            if (that.nameValue) {
                that.$http.updateUserInfo(data).then(() => {
                    that.$common.message('修改成功！', 'success')
                    that.$store.commit('saveUserName', data.userName)
                    that.dialogShow = false
                    that.refresh()
                });
            } else {
                that.$common.message('请输入昵称', 'error')
            }

        },
        // 修改
        change(data) {
            if (data == 'name') {
                this.title = '修改昵称'
            } else if (data == 'phone') {
                this.title = '更改手机号'
            } else if (data == 'pwd') {
                this.title = '修改密码'
            } else if (data == 'role') {
                this.title = '选择角色'
            }
            this.DialogType = data;
            this.dialogShow = true;
        },
        // 关闭弹框
        closeDialog(data) {
            this.dialogShow = data;
            this.nameValue = ''
        },
        // 切换身份
        changeUserType(spType) {
            let that = this;
            that.$http.changeUserType({
                spType,
                userId: parseInt(that.$store.state.userId),
            }).then(res => {
                that.dialogShow = false;
                that.$setStorage('spType', res.data.spType);
                that.$store.commit('spType', res.data.spType);
                that.$common.message('修改成功！', 'success');
                that.$removeStorage('reflush');
                that.$setStorage('InvestigateFlag', res.data.spType == 'S' ? res.data.supplyInvestigateFlag : res.data.procureInvestigateFlag);
                if ((res.data.spType == 'S' && res.data.supplyInvestigateFlag != 'N')) {
                    that.jodVisibleVisible = true;
                } else if ((res.data.spType == 'P' && res.data.procureInvestigateFlag != 'N')) {
                    that.jodVisibleVisible = true;
                } else {
                    that.$setStorage('reflush', true)
                    that.refresh()
                }
            })
        },
        // 调查确认
        verifyJod() {
            if (this.Jod) {
                this.$http.addUserSurveys({
                    typeNote: this.Jod,
                    iuserCode: this.invitationCode,
                    spType: localStorage.getItem('spType') != null ? JSON.parse(localStorage.getItem('spType')) : null,
                    userId: parseInt(this.$store.state.userId),
                }).then(res => {
                    if (res.code == 200) {
                        this.jodVisibleVisible = false;
                        this.$setStorage('reflush', true);
                        this.$setStorage('InvestigateFlag', 'N');
                        this.refresh()
                    }
                })
            } else {
                this.$common.message('请选择您的职业', 'error')
            }

        },
        // 注销用户
        deleteUser() {

            this.outVisibleVisible = true
        },
        verifyDeleteUser() {
            let userId = this.userInfo.userId,
                spType = this.$getStorage('spType'),
                reflush = this.$getStorage('reflush');
            this.$http.deleteUser({
                userId
            }).then(res => {
                if (res.code == 200) {
                    this.outVisibleVisible = false;
                    if (this.$route.name == 'index') {
                        this.reload()
                    }
                    this.$router.push('/home')
                    localStorage.clear();
                    this.$setStorage('spType', spType);
                    this.$setStorage('reflush', reflush);
                } else if (res.code == 275) {
                    this.$common.message(res.msg, 'error')
                    this.outVisibleVisible = false;
                }
            })
        }

    }
}
</script>

<style lang="less" scoped>
.user-base-info {

    .headline {
        font-size: 24px;
        font-family: HarmonyOS Sans SC;
        color: #333333;
        line-height: 30px;
        padding-bottom: 24px;
        border-bottom: 1px solid #F2F2F2;
        font-weight: bold;
    }

    .avatarBox {
        margin-top: 26px;
        height: 160px;
        background: #F4F7FB;
        border-radius: 10px;
        padding: 30px 60px;

        .avatar {
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }

        .avatarBox_right {
            cursor: pointer;

            img {
                width: 13px;
                height: 13px;
                margin-right: 5px;
            }

            span {
                font-size: 16px;
                color: #2971FA;
                font-weight: 400;
            }
        }
    }

    .infoBox {
        margin-top: 20px;
        background: #F4F7FB;
        border-radius: 10px;
        padding: 30px 60px;

        .info {
            line-height: 30px;
            // border: 1px solid #2971FA;
            margin-bottom: 40px;

            .leftBox {
                width: 50%;

                .titleBox {
                    width: 30%;
                    text-align: right;

                    .required {
                        color: red;
                        line-height: 20px;
                        margin-right: 5px;
                    }

                    .title {
                        color: #333333;
                        font-size: 16px;
                        line-height: 20px;
                        text-align: right;
                    }


                }
            }


            .value {
                color: #333333;
                margin-left: 40px;
                width: 200px;
            }

            .change {
                // margin-left: 40px;
                // display: block;
                // color: #2971FA;
                // text-align: center;
                // line-height: 20px;
                // cursor: pointer;
                // &:hover {
                //     cursor: pointer;
                // }
                height: 40px;
                width: 120px;
                margin-left: 40px;
                background: linear-gradient(101deg, #006EF2 0%, #33A6F9 100%);
                display: block;
                color: #fff;
                text-align: center;
                line-height: 40px;
                border-radius: 20px;
                cursor: pointer;
            }

            .changebtn {
                height: 40px;
                width: 120px;
                margin-left: 40px;
                background: linear-gradient(101deg, #006EF2 0%, #33A6F9 100%);
                display: block;
                color: #fff;
                text-align: center;
                line-height: 40px;
                border-radius: 20px;
                cursor: pointer;
            }

        }
    }

    // .info {
    //     margin-bottom: 40px;

    //     .title {
    //         color: #777777;
    //         width: 15%;
    //         font-size: 18px;
    //         text-align: center;
    //     }

    //     .title-1 {
    //         align-self: self-start;
    //     }

    //     .value {
    //         color: #999;
    //     }

    //     .change {
    //         margin-left: 20px;
    //         display: block;
    //         color: #06a1fb;
    //         text-align: center;
    //         line-height: 20px;

    //         &:hover {
    //             cursor: pointer;
    //         }
    //     }
    // }

    // .SignOUTBtn {
    //     margin-left: 25px;
    //     color: #FFF;
    //     font-size: 16px;
    //     width: 160px;
    //     height: 60px;
    //     line-height: 60px;
    //     text-align: center;
    //     border-radius: 10px;
    //     background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);

    //     &:hover {
    //         cursor: pointer;
    //     }
    // }



    .change-name {
        .name {
            font-size: 18px;
            margin-bottom: 25px;
            color: #333;
        }

        .el-button {
            width: 100%;
            margin-top: 25px;
            border-radius: 20px;
        }
    }

    .change-role {
        .name {
            font-size: 24px;
            margin-top: 30px;
            color: #333333;
        }

        .tip {
            margin-top: 10px;
            color: #666;
            font-size: 16px;
        }

        .btn {
            margin-top: 20px;
            height: 60px;
            width: 160px;
            line-height: 60px;
            color: #FFF;
            text-align: center;
            border-radius: 20px;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            cursor: pointer;

        }
    }

    .commonDialog {
        /deep/ .el-dialog__header {
            font-size: 36px !important;
            font-weight: bold;
            padding-top: 60px;
            padding-left: 70px;
        }

        /deep/.el-dialog__title {
            font-size: 36px !important;
        }

        /deep/ .el-dialog__body {

            // padding-top: 60px;
            padding: 50px 70px;
        }
    }

    .jodDialog {
        /deep/ .el-dialog__headerbtn {
            display: none;
        }

        span {
            color: #333333;
            font-size: 30px;
        }

        .tip {
            font-size: 16px;
            color: #333333;
        }

        .btns {
            padding: 16px 30px;
            font-size: 16px;
            text-align: center;
            border-radius: 20px;
            box-shadow: 3px 3px 12px 1px rgba(234, 234, 234, 0.64);
            margin-right: 20px;
            margin-top: 20px;
            cursor: pointer;
        }

        .select {
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            color: #FFF;
            padding: 16px 30px;
            font-size: 16px;
            text-align: center;
            border-radius: 20px;
            box-shadow: 3px 3px 12px 1px rgba(234, 234, 234, 0.64);
            margin-right: 20px;
            margin-top: 20px;
            cursor: pointer;
        }

        .invitationCode {
            margin-top: 20px;

            .title {
                font-size: 16px;
                margin-right: 30px;
            }

            .el-input {
                width: 400px !important;
                height: 60px;
                border: none;
            }

            .el-input__inner {
                height: 60px;
                border: none;
                box-shadow: 3px 3px 12px 1px rgba(234, 234, 234, 0.64);
                padding: 10px 20px;
            }
        }

        .verifyBtn {
            margin: 20px auto 0;
            border-radius: 10px;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            height: 60px;
            width: 160px;
            text-align: center;
            line-height: 60px;
            color: #FFF;
            font-size: 16px;
            cursor: pointer;
        }
    }

    // .outDialog {
    //     .el-dialog__headerbtn {
    //         display: none;
    //     }

    //     span {
    //         color: #333333;
    //         font-size: 30px;
    //     }

    //     .tip {
    //         text-align: center;
    //         font-size: 20px;
    //         color: #333333;
    //         margin-bottom: 30px;
    //     }

    //     .verifyBtn {
    //         margin: 20px auto 0;
    //         border-radius: 10px;
    //         background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
    //         height: 40px;
    //         width: 100px;
    //         text-align: center;
    //         line-height: 40px;
    //         color: #FFF;
    //         font-size: 16px;
    //         cursor: pointer;
    //     }

    //     // background: linear-gradient(0deg, #F33939 0%, #EA5555 100%);
    //     .cancelBtn {
    //         margin: 20px auto 0;
    //         border-radius: 10px;
    //         background: linear-gradient(0deg, #F33939 0%, #EA5555 100%);
    //         height: 40px;
    //         width: 100px;
    //         text-align: center;
    //         line-height: 40px;
    //         color: #FFF;
    //         font-size: 16px;
    //         cursor: pointer;
    //     }
    // }
}
</style>